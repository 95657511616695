import React, { useCallback, useRef, useEffect } from "react";

import ReactPlayer from "react-player";

const TOLERANCE = 0.5; // wiggle room on timing

const VideoPlayer = (props) => {
  const playerRef = useRef(null);
  const { segments, seekTime } = props;

  // This works for small lists, but needs to be refined!
  const onProgress = useCallback(payload => {
    const { playedSeconds } = payload;

    if (segments) {
      segments.forEach((segment, i) => {
        const { end } = segment;
        if ((playedSeconds > end - TOLERANCE) && (playedSeconds < end + TOLERANCE)) {
          if (i + 1 < (segments.length)) {
            playerRef.current.seekTo(segments[i+1].start, "seconds");
          }
        }
      })
    }
  }, [segments]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(seekTime, "seconds");
    }
  } , [seekTime]);

  return (
    <ReactPlayer
      url={props.url}
      playing={true}
      controls
      onProgress={onProgress}
      ref={playerRef}
      // rel attribute is disabled, suggested videos pop up on pause...
      //  https://developers.google.com/youtube/player_parameters?playerVersion=HTML5#Manual_IFrame_Embeds
      // config={
      //   { playerVars: { rel: 0 } }
      // }
    />
  );
}

export default VideoPlayer;
