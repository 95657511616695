module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":960}},{"resolve":"gatsby-remark-embed-youtube"},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem; max-width: 40rem; margin-left: auto; margin-right: auto"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-vscode","options":{"colorTheme":"Material Theme","wrapperClassName":"","injectStyles":true,"extensions":[{"identifier":"Equinusocio.vsc-material-theme","version":"34.3.1"},{"identifier":"silvenon.mdx","version":"0.1.0"}],"languageAliases":{}}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134441849-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"serendipidata","short_name":"serendipidata","start_url":"/","background_color":"#FFF","theme_color":"#81CFE0","display":"standalone","icon":"static/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
