'use strict';

import { wrapRootElement as wrap } from './wrap-root-element';

import './src/assets/scss/init.scss';

// prism theme
// require('./static/gh-prism-theme.css'); // github
// require('./node_modules/prismjs/themes/prism-tomorrow.css');
// import './static/prism-solarizedlight.css';

// Add Custom fonts
// https://dev.to/stoutlabs/speed-tip-use-typefacesjs-in-gatsby-to-locally-host-fonts-6om
import 'typeface-ibm-plex-sans';

export const onClientEntry = () => {};

export const wrapRootElement = wrap;

export default {
  wrapRootElement,
  onClientEntry
};
