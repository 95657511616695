import React, { Component } from 'react';
import { Runtime, Inspector } from '@observablehq/runtime';

import notebook from 'breathing-cube-in-p5-js';

const mountId = 'observable-mount';

class ObservableNotebookImport extends Component {
  componentDidMount() {
    const root = document.getElementById(
      mountId
    );
    // Via https://observablehq.com/@observablehq/downloading-and-embedding-notebooks
    Runtime.load(
      notebook,
      Inspector.into(root)
    );
  }

  render() {
    return (<div id={mountId} style={{ textAlign: 'center' }}> </div>);
  }
}

export default ObservableNotebookImport;
