
import React, { useState, useCallback } from 'react';

import VideoPlayer from './VideoPlayer';


import humanizeDuration from 'humanize-duration';
import Select from 'react-select';

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    }
  }
})

const segments = [
  {
    start: 333, // units are in seconds
    end: 515,
    description: 'Colin Introduction'
  },
  {
    start: 515,
    end: 4059,
    description: 'Interview Section 1'
  },
  {
    start: 5183,
    end: 6945,
    description: 'Interview Section 2'
  },
  {
    start: 6945,
    end: 7100,
    description: 'Auspice / Augur: Technical Overview'
  },
  {
    start: 7100,
    end: 8166,
    description: 'Github Issues Overview'
  }
];

const highlights = [
  {
    start: 718,
    end: 1181,
    description: 'Background / How Colin became involved with Nextstrain'
  },
  {
    description: 'Q: What do uncertainty bounds mean for a tree?',
    start: 1804,
    end: 2121
  },
  {
    description: 'Tree deeper dive',
    start: 2121,
    end: 2323
  },
  {
    start: 2323, // units are in seconds
    end: 2649,
    description: 'Molecular Clock'
  },
  {
    description: 'Target audience / Ebola / Transmission Tracking',
    start: 2877,
    end: 3336
  },
  {
    description: "Recap Shirley's question plan",
    start: 3347,
    end: 3400
  },
  {
    start: 3423,
    end: 3846,
    description: 'How is the data collected / data privacy / cost?'
  },
  {
    start: 5463, // units are in seconds
    end: 5537,
    description: 'Cladogram vs Muller Plot'
  },
  {
    start: 5537, // units are in seconds
    end: 5607,
    description: 'Clades in Phylogenetic Trees'
  },
]

//https://gist.github.com/martinbean/2bf88c446be8048814cf02b2641ba276
const secondsToString = (seconds) => {
  const initial = new Date(seconds * 1000).toISOString().substr(11, 8);
  return initial;
}

const segmentOptions = segments.map(seg => ({
  value: seg,
  label: `${secondsToString(seg.start)} - ${seg.description} (~${shortEnglishHumanizer(1000 * (seg.end - seg.start), {
    units: ['m', 'h'],
    round: true
  })})`
}));

const highlightOptions = highlights.map(seg => ({
  value: seg,
  label: `${secondsToString(seg.start)} - ${seg.description} (~${shortEnglishHumanizer(1000 * (seg.end - seg.start), {
    units: ['m', 'h'],
    round: true
  })})`
}))

const customStyles = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: '1px !important'
  })
};


const NextstrainPlayer = () => {
  const [seekTime, setSeekTime] = useState();

  const onSelect = useCallback((selectedValue) => {
    setSeekTime(selectedValue.value.start);
  }, [setSeekTime]);

  return (
    <div style={{ maxWidth: '40rem', marginLeft: "auto", marginRight: "auto" }}>
      <VideoPlayer
        url="https://www.youtube.com/watch?v=yGAzq4WS7Qc?t=333"
        segments={segments}
        seekTime={seekTime}
      />
      <div style={{ marginTop: '10px' }}>
        <Select
          styles={customStyles}
          options={segmentOptions}
          placeholder={'Main sections'}
          onChange={onSelect}
        ></Select>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Select
          styles={customStyles}
          options={highlightOptions}
          placeholder={'Subsections'}
          onChange={onSelect}
        ></Select>
      </div>
    </div>
  )
}

export default NextstrainPlayer;
