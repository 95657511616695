// https://github.com/hagnerd/gatsby-starter-blog-mdx/pull/1/files
// https://github.com/ChristopherBiscardi/gatsby-mdx/blob/master/examples/docs/src/components/mdxComponents/index.js
// Replace with VSCode syntax highlighting for now...
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
// import { get } from 'lodash';

// import Code from './src/components/code';

// const Pre = (props) => {
//   // Block
//   if (get(props, 'children.props.mdxType') === 'code') {
//     // mdxTag has children prop, which is source code
//     // mdxTagProps.props is the props to pass to `code`, including meta props
//     const mdxTagProps = props.children.props;
//     let lang;
//     if (mdxTagProps.className) {
//       const match = mdxTagProps.className.split('language-');
//       if (match && match[1]) {
//         lang = match[1];
//       }
//     } else {
//       lang = ('markup');
//     }
//     return (
//       <Code is="block" {...mdxTagProps} {...mdxTagProps.props} lang={lang} />
//     );
//   }
//   return <pre {...props} />;
// };

// components is its own object outside of render so that the references to
// components are stable
const components = {
  // pre: Pre,
  // code: (props) => <Code is="block" {...props} />,
  // inlineCode: Code
};

export const wrapRootElement = ({ element }) => (
    <MDXProvider components={components}>{element}</MDXProvider>
);

export default wrapRootElement;
